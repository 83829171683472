<template>

  <b-card-code
    no-body
  >
    
     
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
       
          <b-table 
            responsive
            :items="items" 
            :fields="tableColumns" 
            striped 
            class="position-relative"
          >

          <template #cell(id)="data">
              {{data.index + 1}}
          </template>



          <template #cell(image)="data">
            <b-avatar :src="data.value" />
          </template>


          

          <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
               

                <b-dropdown-item :to="{ name: 'policy-update', params: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item>

              </b-dropdown>
            </template>

          </b-table>

          
        

        </b-overlay>
      <!-- </div> -->
  </b-card-code>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, 
        BDropdown, BButton,BCardBody, BPagination  
      } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'



export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BButton,
    BCardBody,
    ToastificationContent,
    BCardCode,
    BPagination,
  },

  data() {
    return {
      items: [],
      isLoading: true,
      tableColumns: [
        { key: 'id',label: 'الرقم', sortable: true },
        { key: 'name', label: ' اﻹسم', sortable: true },
        
        { key: 'actions',label: ' اﻹجراءات' },
      ],
      
      
      
    }
  },
  methods: {
    getData(){
      this.isLoading = true;
      DataService.getAll()
      .then(response => {
        this.isLoading = false
        this.items = response.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
        })
    },
  },
  mounted() {
    this.getData()
  },
  
}
</script>