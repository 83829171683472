import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/Policies");
  }
  get(id) {
    return axios.get(`/Policies/${id}`);
  }
  create(data) {
    return axios.post("/Policies", data);
  }
  update(id, data) {
    return axios.put(`/Policies`, data);
  }
  delete(id) {
    return axios.delete(`/Policies/${id}`);
  }

  
}
export default new DataService();